import React from 'react'
import { play01, Timer , Import, Key, Mobile,Chapter} from "../../../../helpers/utils/image/imagePath";

const IncludesSection = () => {
  return (
    <div className="sidebar-sec m-0">
              {/* Include */}
              <div className="card include-sec">
                <div className="card-body">
                  <div className="cat-title">
                    <h4>Includes</h4>
                  </div>
                  <ul>
                    <li>
                      <img src={Import} className="me-2" alt="" />  11 hours on-demand video
                    </li>
                    <li>
                      <img src={play01} className="me-2" alt="" />  69 downloadable resources
                    </li>
                    <li>
                      <img src={Key} className="me-2" alt="" /> Full lifetime access
                    </li>
                    <li>
                      <img src={Mobile} className="me-2" alt="" /> Access on mobile and TV
                    </li>
                    <li>
                      <img src={Timer} className="me-2" alt="" />  Duration: 20 hours
                    </li>
                    
                    <li>
                      <img src={Chapter} className="me-2" alt="" />  Chapters: 15
                    </li>
                    <li>
                      <img src={play01} className="me-2" alt="" />  Video: 12 hours
                    </li>

                  </ul>
                </div>
              </div>
              {/* /Include */}
            </div>
  )
}

export default IncludesSection


