import React from "react";
import { useSelector } from "react-redux";
import Proptypes from "prop-types";

import banner from "../../../assets/image/inner-bg.png";
import { Icon1, Timer, User1, userAvatar } from "../../../helpers/utils/image/imagePath";

const CourseInstBannerSection = () => {
  const { course } = useSelector((state) => state.studentSlice);

  return (
    <div className="inner-banner" style={{ backgroundImage: "url(" + course?.image || banner + ")" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="instructor-wrap border-bottom-0 m-0">
              <div className="about-instructor align-items-center">
                <div className="abt-instructor-img">
                  <img src={course?.instructor?.image || userAvatar} alt="img" className="img-fluid object-fit-cover" />
                </div>
                <div className="instructor-detail me-3">
                  <h5>{course?.instructor?.name}</h5>
                  {/* <p>UX/UI Designer</p> */}
                </div>
              </div>
            </div>
            <h2>{course?.name}</h2>
            <p dangerouslySetInnerHTML={{ __html: course?.description }}></p>
            <div className="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
              <div className="cou-info">
                <img src={Icon1} alt="" />
                <p>{course?.total_lessons || 0} Lesson</p>
              </div>
              <div className="cou-info">
                <img src={Timer} alt="" />
                <p>{course?.total_duration || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseInstBannerSection;
