import React from "react";
import PropTypes from "prop-types";
import { Trash2 } from "react-feather";
import "./index.css";

export const DeleteTextButton = ({ text, onClick }) => {
  return (
    <button type="button" className="sc_delete_text_button" onClick={onClick}>
      <Trash2 size={20} className="delete__icon" />
      {text}
    </button>
  );
};

DeleteTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const PrimaryButton = ({ type, text, onClick, loading }) => {
  return (
    <button type={type || "button"} className="sc_button sc_primary_button" onClick={onClick}>
      {!loading ? text : "loading...."}
    </button>
  );
};

export const PrimaryTextButton = ({ text, onClick }) => {
  return (
    <button type={"button"} className="sc_text_button sc_text_primary_button" onClick={onClick}>
      {text}
    </button>
  );
};

export const DangerButton = ({ type, text, onClick, className }) => {
  return (
    <button type={type || "button"} className={`sc_button sc_danger_button ${className}`} onClick={onClick}>
      {text}
    </button>
  );
};

export const DangerTextButton = ({ text, onClick }) => {
  return (
    <button type={"button"} className="sc_text_button sc_text_danger_button" onClick={onClick}>
      {text}
    </button>
  );
};
PrimaryButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

PrimaryTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
DangerTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
DangerButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};
