import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Chapter from "./Chapter";
import AddChapterForm from "./AddChapterForm";
import AddLessonFrom from "./AddLessonFrom";
import { useGetChaptersByIdQuery } from "../../../../services/admin/addCourse/chapter/chapter";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { deleteLesson } from "../../../../store/features/admin/lesson/lesson.api";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";

const AddCurriculum = ({ nextTab2, prevTab1 }) => {
  const { id } = useParams();
  const [openAddLessonModal, setOpenLessonModal] = useState(false);
  const [openChapternModal, setOpenChapternModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeChapter, setActiveChapter] = useState();
  const [activeLesson, setActiveLesson] = useState();
  const { isSuccess, data: allChapters } = useGetChaptersByIdQuery(id);
  const lessonState = useAppSelector((state) => state.lessonSlice);
  const dispatch = useAppDispatch();

  console.log("activeLesson--", activeLesson);
  const removeLesson = () => {
    dispatch(deleteLesson(activeLesson.id));
  };

  const openDeleteModals = () => {
    setOpenDeleteModal(true);
  };

  useEffect(() => {
    if (lessonState.is_success.deleteLesson) {
      setOpenDeleteModal(false);
    }
  }, [lessonState.is_success.deleteLesson]);

  return (
    <fieldset className="field-card" style={{ display: "block" }}>
      <div className="add-course-info">
        <AddChapterForm
          activeChapter={activeChapter}
          openChapternModal={openChapternModal}
          setOpenChapternModal={setOpenChapternModal}
          setActiveChapter={setActiveChapter}
        />
        <AddLessonFrom
          modalOpen={openAddLessonModal}
          setActiveChapter={setActiveChapter}
          setModalOpen={setOpenLessonModal}
          activeChapter={activeChapter}
        />

        <DeleteModal
          title="Delete Lesson ?"
          modalOpen={openDeleteModal}
          setModalOpen={setOpenDeleteModal}
          onClick={removeLesson}
          description={`Are you sure you want to delete ${activeLesson?.name}`}
          loading={lessonState.is_loading.deleteLesson}
        />

        <div className="add-course-form">
          {isSuccess &&
            allChapters.data.map((chapter) => {
              return (
                <Chapter
                  key={chapter.id}
                  setOpenLessonModal={setOpenLessonModal}
                  data={chapter}
                  setActiveChapter={setActiveChapter}
                  setOpenChapternModal={setOpenChapternModal}
                  openDeleteModal={openDeleteModals}
                  setActiveLesson={setActiveLesson}
                  activeLesson={activeLesson}
                />
              );
            })}
        </div>
        <Footer prevTab1={prevTab1} nextTab2={nextTab2} />
      </div>
    </fieldset>
  );
};

export default AddCurriculum;

AddCurriculum.propTypes = {
  nextTab2: PropTypes.any.isRequired,
  prevTab1: PropTypes.any.isRequired,
};
