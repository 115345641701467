/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../../../../helpers/utils/image/imagePath";

const FileUploader = ({ setFileInfo }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    console.log("a---", acceptedFiles[0]);
    setFileInfo({ file: acceptedFiles[0] });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.rar": [".rar"],
      "application/zip": [".zip"],
    },
  });
  return (
    <div className="form-control sc_basic_course_file_upload_section sc_lesson_file_upload_height" {...getRootProps()}>
      <div className="sc_file_upload_section">
        <img src={uploadFile} className="mb--05" />
        <span className="sc_upload_file_description">
          Drop your images here, or <span className="sc_upload_file_colorful_text">browse</span>
          <span className="second_desc">Supports PDF, doc and ppt files</span>
        </span>
      </div>

      <input {...getInputProps()} />
    </div>
  );
};

export default FileUploader;
