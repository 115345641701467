import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import localDb from "../../helpers/utils/localDb/localDb";
import { useAppSelector } from "../../store/store";
import { ROLES } from "../../helpers/constants/roles";

const Admin = () => {
  const authState = useAppSelector((state) => state.authSlice);
  const token = localDb.fetch("access_token");
  const user = localDb.fetch("user");
  return token && user?.role === ROLES.ADMIN ? <Outlet /> : <Navigate to="/not-found" />;
  // return token ? <Outlet /> : <Navigate to="/not-found" />;
};

export default Admin;
