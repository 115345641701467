import React from "react";
import AdminLayoutWithoutSidebar from "../../../layout/admin/AdminLayoutWithoutSidebar";
import CourseDetailWrapper from "./Wrapper";
import CourseDetailsComp from "../../../components/students/CourseDetails";

const CourseDetail = () => {
  return (
    <AdminLayoutWithoutSidebar>
      <CourseDetailWrapper>
        <CourseDetailsComp />
      </CourseDetailWrapper>
    </AdminLayoutWithoutSidebar>
  );
};

export default CourseDetail;
