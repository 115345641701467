import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";

const CourseBreadCrumbBar = ({courseTitle="Default title"}) => {
  return (
    <div className="breadcrumb-bar">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="breadcrumb-list">
            <nav aria-label="breadcrumb" className="page-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Courses
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  {courseTitle}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CourseBreadCrumbBar
CourseBreadCrumbBar.propTypes = {
    courseTitle:PropTypes.string.isRequired

}

