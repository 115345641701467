import React from "react";
import { useSelector } from "react-redux";

const CourseOverviewSection = () => {
  const { course } = useSelector((state) => state.studentSlice);
  return (
    <div className="card overview-sec">
      <div className="card-body">
        <h5 className="subs-title">Overview</h5>
        <h6>Course Description</h6>
        <div dangerouslySetInnerHTML={{ __html: course?.description }} />
        {course?.requirement && (
          <>
            <h6>Requirements</h6>
            <div dangerouslySetInnerHTML={{ __html: course?.requirements }} />
          </>
        )}
      </div>
    </div>
  );
};

export default CourseOverviewSection;
