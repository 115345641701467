import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as tus from "tus-js-client";
import { sha256 } from "js-sha256";
import axiosInstance from "../../../../settings/axiosInstance";
import { handleApiError } from "../../../../helpers/utils/apiError/apiError";
import { BASE_URL, BUNNY_API_KEY, BUNNY_VIDEO_LIBRARY_ID } from "../../../../settings/urlConfig";
import { TosatMessage } from "../../../../helpers/utils/toaster";

export const addLessonApi = createAsyncThunk(
  "addLessonApi",
  async ({ formData, setFileUploadedProgress }, rejectWithValue) => {
    try {
      const res = await axiosInstance.post(`${BASE_URL}/lessons`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setFileUploadedProgress(progress);
        },
      });
      return res.data;
    } catch (err) {
      handleApiError(err, rejectWithValue);
    }
  },
);

export const createVideoId = createAsyncThunk("createVideoId", async (data, rejectWithValue) => {
  try {
    const res = await axios.post(`https://video.bunnycdn.com/library/${BUNNY_VIDEO_LIBRARY_ID}/videos`, data, {
      headers: {
        AccessKey: BUNNY_API_KEY,
      },
    });
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});

export const deleteLesson = createAsyncThunk("deleteLesson", async (id, rejectWithValue) => {
  try {
    const res = await axiosInstance.delete(`${BASE_URL}/lessons/${id}`);
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});

export const uploadVideoToBunny = createAsyncThunk(
  "uploadVideoToBunny",
  async ({ data, videoId, setTsuInstance, setVideoStatus, videoStatus, setVideoUploadedProgress }, rejectWithValue) => {
    const expiration_time = Math.floor(Date.now() / 1000) + 24 * 60 * 60;
    try {
      setVideoStatus({ ...videoStatus, loading: true });
      // Create a new tus upload
      const res = new tus.Upload(data, {
        uploadUrl: "https://video.bunnycdn.com/tusupload",
        endpoint: "https://video.bunnycdn.com/tusupload",
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
          AuthorizationSignature: sha256(143042 + BUNNY_API_KEY + expiration_time + videoId), // SHA256 signature (library_id + api_key + expiration_time + video_id)
          AuthorizationExpire: expiration_time, // Expiration time as in the signature,
          VideoId: videoId, // The guid of a previously created video object through the Create Video API call
          LibraryId: BUNNY_VIDEO_LIBRARY_ID,
        },
        metadata: {
          filetype: data.type,
          title: data.name,
        },
        onError: function (error) {
          console.log(error, "error");
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          const percent = Math.floor((bytesUploaded / bytesTotal) * 100);
          setVideoUploadedProgress(percent);
        },
        onSuccess: function () {
          setVideoStatus({ ...videoStatus, success: true, loading: false });
        },
      });
      setTsuInstance(res),
        // Check if there are any previous uploads to continue.
        res.findPreviousUploads().then(function (previousUploads) {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            res.resumeFromPreviousUpload(previousUploads[0]);
          }

          // Start the upload
          res.start();
        });
    } catch (err) {
      handleApiError(err, rejectWithValue);
    }
  },
);
