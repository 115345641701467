/* eslint-disable no-undef */
import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import RootReducer from "./rootReducer";
import { pokemonApi } from "../services/sample";
import { instructorApi } from "../services/admin/instructor/instructor";
import { rtkQueryErrorLogger } from "../logger/rtkQueryErrorLogger";
import { enrolledStudentApi } from "../services/admin/student/student";
import { addCourseBasicInfoApi } from "../services/admin/addCourse/basicInfo/course";
import { chapterApi } from "../services/admin/addCourse/chapter/chapter";
import { coursesApi } from "../services/admin/course/courses";

const Store = configureStore({
  reducer: RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      thunkMiddleware,
      rtkQueryErrorLogger,
      pokemonApi.middleware,
      instructorApi.middleware,
      enrolledStudentApi.middleware,
      addCourseBasicInfoApi.middleware,
      chapterApi.middleware,
      coursesApi.middleware,
    ),
});

setupListeners(Store.dispatch);
export const useAppDispatch = () => useDispatch();
export const useAppSelector = (slice) => useSelector(slice);

export default Store;
