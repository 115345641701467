import { createSlice } from "@reduxjs/toolkit";
import { getCourseDetail, getStudentCourses } from "./student.api";

const initialState = {
  loading: false,
  courses: [],
  course: null,
};

const studentSlice = createSlice({
  name: "studentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get student courses
    builder.addCase(getStudentCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStudentCourses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.courses = payload?.data;
    });
    builder.addCase(getStudentCourses.rejected, (state) => {
      state.loading = false;
    });

    //get course detail
    builder.addCase(getCourseDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCourseDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.course = payload?.data;
    });
    builder.addCase(getCourseDetail.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default studentSlice.reducer;
