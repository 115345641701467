/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "./index.css";
import { DangerButton } from "../../../../../shared/button/Button";

const ProgressBar = ({ uploadProgress, cancelUpload }) => {
  return (
    <div className="sc_video__loader__wrapper">
      <div className="sc_circular_progress_bar">
        <CirCularProgressBar percentage={uploadProgress} circleWidth={100} />
        <p className="sc_uploading__text">uploading...</p>
        <DangerButton text="Cancel Upload" onClick={cancelUpload} className="sc_zindex" />
      </div>
    </div>
  );
};

export default ProgressBar;

const CirCularProgressBar = ({ percentage }) => {
  const circleWidth = 100;
  //   const { percentage, circleWidth } = props;
  const radius = 30;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;
  return (
    <div className="sc_progressbar">
      <svg width={circleWidth} height={circleWidth} viewBox={`0 0 ${circleWidth} ${circleWidth}`}>
        <circle cx={circleWidth / 2} cy={circleWidth / 2} strokeWidth="10px" r={radius} className="circle-bg" />
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="10px"
          r={radius}
          className="sc_circle-progress"
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`}
        />
        <text x="50%" y="50%" dy="0.3em" textAnchor="middle" className="sc_circle_text" fill="white">
          {percentage}%
        </text>
      </svg>
    </div>
  );
};
