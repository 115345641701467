import React from "react";
import PropTypes from "prop-types"

const VideoCard = ({videoUrl}) => {
  return (
    <div className="card content-sec">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-6">
            <h5 className="video__introduction">Introduction</h5>
          </div>
        </div>
        <video className="video__section" controls>
          <source src={videoUrl} />
        </video>
      </div>
    </div>
  );
};

export default VideoCard;

VideoCard.propTypes = {
    videoUrl:PropTypes.string.isRequired

}
