/* eslint-disable no-undef */
const host = window.location.hostname;
const checkhost = host.split(".");
const tenant = checkhost[0];

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? `https://backend.scholarsai.com/api/${tenant}`
    : `https://backend.scholarsai.com/api/${tenant}`;

export const BASE_HOST = process.env.NODE_ENV === "development" ? "localhost" : "agileacademy";
export const BUNNY_VIDEO_LIBRARY_ID = "143042";
export const BUNNY_API_KEY = "d1a6b9d1-929c-46ee-bd13bd17eff1-22ff-4f38";

export const BACK_TO_HOME_URL =
  process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000/" : "https://scholarsai.com/";
export const GO_TO_TERMS_AND_CONDITION_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000/term-condition"
    : "https://scholarsai.com/term-condition";
export const GO_TO_PRIVACY_AND_POLICY_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000/privacy-policy"
    : "https://scholarsai.com/privacy-policy";
