export const learningPathCoursesData = [
    {
      id:1,
      course_name:"Information About UI/UX Design Degree",
      image_url:"https://dreamslms.dreamguystech.com/html/assets/img/course/course-10.jpg",
      course_count:10,
      course_duration:"9hr 30min",
      course_description:"UI/UX courses typically cover a wide range of topics, including the principles of design, user research, wireframing, prototyping, interaction design, visual design, usability testing, and more. Students learn how to create user-centric designs that meet the needs and preferences of their target audience.",
      courses:[
        {
          id:1,
          title:"Introduction to HTML5 and CSS31",
        },
        {
          id:2,
          title:"Responsive Web Design1",
        },
        {
          id:3,
          title:"Flexbox and Grid Layouts1",
        },
        {
          id:4,
          title:"Interactive Elements1",
        },
      ]
  
  
    },
    {
      id:2,
      course_name:"Information About UI/UX Design Degree2",
      image_url:"https://dreamslms.dreamguystech.com/html/assets/img/course/course-10.jpg",
      course_count:10,
      course_duration:"9hr 30min",
      course_description:"UI/UX courses typically cover a wide range of topics, including the principles of design, user research, wireframing, prototyping, interaction design, visual design, usability testing, and more. Students learn how to create user-centric designs that meet the needs and preferences of their target audience.",
      courses:[
        {
          id:1,
          title:"Introduction to HTML5 and CSS32",
        },
        {
          id:2,
          title:"Responsive Web Design2",
        },
        {
          id:3,
          title:"Flexbox and Grid Layouts2",
        },
        {
          id:4,
          title:"Interactive Elements2",
        },
      ]
  
  
    },
    {
      id:3,
      course_name:"Information About UI/UX Design Degree3",
      image_url:"https://dreamslms.dreamguystech.com/html/assets/img/course/course-10.jpg",
      course_count:10,
      course_duration:"9hr 30min",
      course_description:"UI/UX courses typically cover a wide range of topics, including the principles of design, user research, wireframing, prototyping, interaction design, visual design, usability testing, and more. Students learn how to create user-centric designs that meet the needs and preferences of their target audience.",
      courses:[
        {
          id:1,
          title:"Introduction to HTML5 and CSS3 3",
        },
        {
          id:2,
          title:"Responsive Web Design 3",
        },
        {
          id:3,
          title:"Flexbox and Grid Layouts 3",
        },
        {
          id:4,
          title:"Interactive Elements 3",
        },
      ]
  
  
    },
    {
      id:4,
      course_name:"Information About UI/UX Design Degree4",
      image_url:"https://dreamslms.dreamguystech.com/html/assets/img/course/course-10.jpg",
      course_count:10,
      course_duration:"9hr 30min",
      course_description:"UI/UX courses typically cover a wide range of topics, including the principles of design, user research, wireframing, prototyping, interaction design, visual design, usability testing, and more. Students learn how to create user-centric designs that meet the needs and preferences of their target audience.",
      courses:[
        {
          id:1,
          title:"Introduction to HTML5 and CSS3 4",
        },
        {
          id:2,
          title:"Responsive Web Design 4",
        },
        {
          id:3,
          title:"Flexbox and Grid Layouts 4",
        },
        {
          id:4,
          title:"Interactive Elements 4",
        },
      ]
  
  
    },
    {
      id:5,
      course_name:"Information About UI/UX Design Degree5",
      image_url:"https://dreamslms.dreamguystech.com/html/assets/img/course/course-10.jpg",
      course_count:10,
      course_duration:"9hr 30min",
      course_description:"UI/UX courses typically cover a wide range of topics, including the principles of design, user research, wireframing, prototyping, interaction design, visual design, usability testing, and more. Students learn how to create user-centric designs that meet the needs and preferences of their target audience.",
      courses:[
        {
          id:1,
          title:"Introduction to HTML5 and CSS3 5",
        },
        {
          id:2,
          title:"Responsive Web Design 5",
        },
        {
          id:3,
          title:"Flexbox and Grid Layouts 5",
        },
        {
          id:4,
          title:"Interactive Elements 5",
        },
      ]
  
  
    },
  ]
  