import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { learningPathCoursesData } from "./helper";
import SearchBar from "../../../shared/searchBar/SearchBar";
import LearningPathCoursesItem from "./LearningPathCoursesItem"
import Empty from "../../../shared/Empty/Empty";

const LearningPathCoursesComp = () => {
  const [searchText, setSearchText] = useState("");
  const navigate =  useNavigate();
  const { id } = useParams();

  console.log("Id from learning courses", id);
  console.log(typeof id);

  const data = learningPathCoursesData.filter((item) => item.id == id);
  console.log("From courses component:", data);
  
  const handleOnClick = (courseId)=>{
    navigate(`/course-detail/${courseId}`)
    console.log("Handle onClick clicked...")
  }

  return (
    <div className="container">
      <div className="student-widget-group p-0 mt-4">
        <div className="row">
          <div className="col-lg-12 ">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />

            <div className="row">
              <div className="courses__title mb-2">COURSES IN LEARNING PATH</div>
              {data[0]?.courses?.map((item) => (
                <LearningPathCoursesItem key={item.id} title={item.title} onClick={()=>handleOnClick(item.id)}/>
              ))}
    
              {/* Empty Screen  */}
              {/* <Empty emptyText="No Courses added in learning path."/> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningPathCoursesComp;
