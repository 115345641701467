import React from "react";
import { Search } from "react-feather";
import PropTypes from "prop-types";

const SearchBar = ({ searchText, setSearchText, placeholder }) => {
  return (
    <div className="showing-list">
      <div className="row">
        <div className="col-lg-12">
          <div className="show-filter choose-search-blk">
            <div className="mycourse-student align-items-center">
              <div className="student-search">
                <div className=" search-group">
                  <Search className="searchFeather" size={16} />
                  <input
                    type="text"
                    className="form-control"
                    placeholder={placeholder || "search"}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
SearchBar.propTypes = {
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
