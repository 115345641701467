import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import { AdminLayout } from "../../../layout/admin/AdminLayout";
import InstructorMainWrapper from "./Wrapper";
import InstructorHeader from "../../../components/admin/instructor/InstructorHeader";
import InstructorTable from "../../../components/admin/instructor/InstructorTable";
import AddInstructorFrom from "../../../components/admin/instructor/AddInstructorFrom";
import DeleteModal from "../../../shared/deleteModal/DeleteModal";
import Pagination from "../../../shared/pagination/Pagination";
import { useDeleteInstructorMutation, useGetInstructorQuery } from "../../../services/admin/instructor/instructor";

const Instructors = () => {
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [debouncedSerach] = useDebounce(searchText, 500);
  const [debouncedPage] = useDebounce(page, 400);

  const { isFetching, isSuccess, data } = useGetInstructorQuery({ debouncedSerach, debouncedPage });

  const [deleteInstructor, { isLoading: isDeleting, isSuccess: isDeleted }] = useDeleteInstructorMutation();

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setDeletepenModal] = useState(false);
  const [activeData, setActiveData] = useState("");

  const [isEdit, setEdit] = useState(false);

  const deleteInstructorFromList = () => {
    deleteInstructor(activeData.id);
  };

  useEffect(() => {
    if (isDeleted) {
      setDeletepenModal(false);
    }
  }, [isDeleted]);

  return (
    <AdminLayout>
      <InstructorMainWrapper>
        <InstructorHeader setOpenModal={setOpenModal} searchText={searchText} setSearchText={setSearchText} />
        <InstructorTable
          setDeletepenModal={setDeletepenModal}
          isSuccess={isSuccess}
          data={data?.data?.data}
          setActiveData={setActiveData}
          setOpenModal={setOpenModal}
          setEdit={setEdit}
        />
        <Pagination data={data?.data?.pagination} setPage={setPage} />
        <AddInstructorFrom
          openModal={openModal}
          setOpenModal={setOpenModal}
          isEdit={isEdit}
          setEdit={setEdit}
          activeData={activeData}
        />
        <DeleteModal
          modalOpen={openDeleteModal}
          setModalOpen={setDeletepenModal}
          onClick={deleteInstructorFromList}
          title="Delete Instructor ?"
          description="Are you sure you want to delete Instructor Guy Hawkins"
          loading={isDeleting}
        />
      </InstructorMainWrapper>
    </AdminLayout>
  );
};

export default Instructors;
