/* eslint-disable react/prop-types */
import React from "react";
import { Edit, Edit2, Trash2 } from "react-feather";

const Lesson = ({ lesson, openDeleteModal, setActiveLesson, chapterId }) => {
  const openModal = () => {
    setActiveLesson({ ...lesson, chapter_id: chapterId });
    openDeleteModal();
  };
  return (
    <div className="faq-grid">
      <div className="sc_add__lesson__section">
        <span className="lesson_name">{lesson?.name}</span>
        <div className="sc_lesson_button__section">
          <Edit size={18} className="edit__icon" /> <Trash2 size={18} className="trash_icon" onClick={openModal} />
        </div>
      </div>
    </div>
  );
};

export default Lesson;
