import React from "react";
import PropTypes from "prop-types";

import { Header } from "./Header";
import Sidebar from "./Sidebar";

export const AdminLayout = ({ children }) => {
  return (
    <div className="main-wrapper">
      <Header activeMenu={"Dashboard"} />
      <div className="page-content instructor-page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <Sidebar activeMenu={"Dashboard"} />
            {/* Sidebar */}

            {/* content */}
            {children}
            {/* content */}
          </div>
        </div>
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
