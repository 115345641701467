import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";

import CourseCard from "./CourseCard/CourseCard";
import SearchBar from "../../../shared/searchBar/SearchBar";
import Empty from "../../../shared/Empty/Empty";
import { getStudentCourses } from "../../../store/features/Student/student.api";

const StudentCoursesComp = () => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [search] = useDebounce(searchText, 1000);
  const { courses } = useSelector((state) => state.studentSlice);

  useEffect(() => {
    dispatch(getStudentCourses({ searchText: search, page }));
  }, [search, page]);

  const handleOnClick = (courseId) => {
    navigate(`/course-detail/${courseId}`);
  };

  return (
    <div className="container">
      <div className="student-widget-group p-0 mt-4">
        <div className="row">
          <div className="col-lg-12 ">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
            <div className="row">
              {courses?.data?.length > 0 ? (
                courses?.data.map(({ id, image, name, progress }) => (
                  <CourseCard
                    key={id}
                    imageUrl={image}
                    title={name}
                    progress={progress}
                    onClick={() => handleOnClick(id)}
                  />
                ))
              ) : (
                <Empty emptyText="No Courses added." />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCoursesComp;
