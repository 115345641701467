import React from "react";
import { Route, Routes } from "react-router-dom";
import ROUTES from "../helpers/constants/routes";
import Login from "../pages/auth/login";
import SingnUp from "../pages/auth/signup";
import ForgotPassword from "../pages/auth/forgotPassword";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";

const AuthRoute = () => {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.SIGNUP} element={<SingnUp />} />

      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
    </Routes>
  );
};

export default AuthRoute;
