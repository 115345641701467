const ROUTES = {
  // FOR AUTH
  LOGIN: "/",
  SIGNUP: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/forgot-password/verify",

  // FOR ADMIN
  DASHBOARD: "/dashboard",
  COURSES: "/courses",
  ADD_NEW_COURSE: "/add-new/course",
  INSTRUCTORS: "/instructors",
  STUDENTS: "/students",

  // FOR INSTRUCTORS

  // FOR STUDENT
  STUDENT_COURSES: "/my-courses",
  STUDENT_LEARNING_PATH: "/learning-paths",

  PROFILE:"/profile",
  LEARNING_PATH_COURSES:"/learning-path-courses/:id",
  COURSES_DETAILS:"/course-detail/:courseId"

};

export default ROUTES;
