/* eslint-disable react/prop-types */
import React from "react";

const Footer = ({ nextTab, loading }) => {
  //  onClick = { nextTab };
  return (
    <div className="widget-btn">
      <button to="#" className="btn btn-black">
        Back
      </button>
      <button type="submit" className="btn btn-info-light next_btn">
        {!loading ? "Continue" : "Loading..."}
      </button>
      <button type="button" className="btn btn-info-light next_btn" onClick={nextTab}>
        Next
      </button>
    </div>
  );
};

export default Footer;
