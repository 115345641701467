/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../shared/modal/Modal";
import TextField from "../../../../shared/inputs/TextField/TextField";
import ModalFooter from "../../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../../shared/button/Button";
import FileUploader from "./FileUploader";
import VideoUploader from "./VideoUploader";
import UploadProgress from "./UploadProgress";
import ErrorText from "../../../../shared/inputs/errorText/ErrorText";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { addLessonApi, createVideoId, uploadVideoToBunny } from "../../../../store/features/admin/lesson/lesson.api";
import ProgressBar from "./videoUploadProgress/VideoUploadProgress";

const AddLessonFrom = ({ modalOpen, setModalOpen, activeChapter, setActiveChapter }) => {
  const lessonState = useAppSelector((state) => state.lessonSlice);
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState("");
  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState("");
  const [fileInfo, setFileInfo] = useState({});
  const [videoFileInfo, setVideoFileInfo] = useState({});
  const [fileUploadedProgress, setFileUploadedProgress] = useState(0);
  const [videoUploadedProgress, setVideoUploadedProgress] = useState(0);
  const [tsuInstance, setTsuInstance] = useState();
  const [videoStatus, setVideoStatus] = useState({ loading: false, success: false });
  const [error, setError] = useState({ title: "", video: "", file: "", url: "" });
  const videoRef = useRef();
  const [selectedVideoFileUrl, setSelectedVideoFileUrl] = useState("");

  const handleError = () => {
    if (!title) {
      setError({ ...error, title: "Title is required!" });
    }
  };

  const clearFrom = () => {
    setError({});
    setTitle("");
    setYoutubeVideoUrl("");
    setFileInfo({});
    setVideoFileInfo({});
    setTsuInstance();
    setVideoStatus({ loading: false, success: false });
    setFileUploadedProgress(0);
    setVideoUploadedProgress(0);
    setSelectedVideoFileUrl("");
    videoRef?.current?.load();
  };

  const clearFile = () => {
    setFileInfo({});
  };
  const clearVideo = () => {
    setVideoFileInfo({});
  };
  const cancelUpload = () => {
    tsuInstance.abort();
    clearFrom();
  };

  const addLessonWithoutVideo = (e) => {
    e.preventDefault();
    handleError();
    const formData = new FormData();
    formData.append("name", title);
    formData.append("chapter_id", activeChapter.id);
    if (fileInfo.file) {
      formData.append("type", "file");
      formData.append("attachment_url", fileInfo.file);
    }
    if (youtubeVideoUrl) {
      formData.append("type", "other_video");
      formData.append("other_video_url", youtubeVideoUrl);
    }

    dispatch(addLessonApi({ formData, setFileUploadedProgress }));
  };

  const addLessonWithVideo = () => {
    if (videoFileInfo.file) {
      const data = { title: title };
      dispatch(createVideoId(data));
    }
  };

  useEffect(() => {
    if (lessonState.is_success.addLesson) {
      setModalOpen(false);
      setActiveChapter();
      clearFrom();
    }
  }, [lessonState.is_success.addLesson]);

  useEffect(() => {
    if (lessonState.is_success.videoId) {
      dispatch(
        uploadVideoToBunny({
          data: videoFileInfo.file,
          videoId: lessonState.bunny_video_id,
          setTsuInstance: setTsuInstance,
          setVideoStatus: setVideoStatus,
          videoStatus: videoStatus,
          setVideoUploadedProgress: setVideoUploadedProgress,
        }),
      );
    }
  }, [lessonState.is_success.videoId]);

  useEffect(() => {
    if (videoStatus.success) {
      const formData = new FormData();
      formData.append("name", title);
      formData.append("chapter_id", activeChapter.id);
      formData.append("type", "video");
      formData.append("video_url", lessonState.bunny_video_id);

      dispatch(addLessonApi({ formData, setFileUploadedProgress }));
    }
  }, [videoStatus.success]);

  return (
    <Modal title="Add Chapter" modalOpen={modalOpen} setModalOpen={setModalOpen} onClear={clearFrom}>
      <form className="mt-3" encType="multipart/form-data">
        <TextField
          name="name"
          label="Lesson Name"
          value={title}
          onInput={(e) => setTitle(e.target.value)}
          error={error.title}
        />
        <ErrorText error={error.title} className="marginTop-13 mb-1" />
        {!fileInfo.file && !youtubeVideoUrl && (
          <div className="form-group">
            <label className="add-course-label">Upload Media</label>
            <VideoUploader
              setVideoFileInfo={setVideoFileInfo}
              clearVideo={clearVideo}
              videoRef={videoRef}
              selectedVideoFileUrl={selectedVideoFileUrl}
              setSelectedVideoFileUrl={setSelectedVideoFileUrl}
            />
            <ErrorText error={error.video} />
          </div>
        )}
        {!fileInfo.file && !youtubeVideoUrl && !videoFileInfo.file && (
          <div className="form-group">
            <label className="add-course-label">Upload File</label>
            <FileUploader setFileInfo={setFileInfo} />
            <ErrorText error={error.file} />
          </div>
        )}
        {fileInfo.file && <UploadProgress progress={fileUploadedProgress} clearFile={clearFile} />}
        {!fileInfo.file && !videoFileInfo.file && (
          <>
            {" "}
            <TextField
              type="url"
              name="name"
              label="Video url"
              value={youtubeVideoUrl}
              onInput={(e) => setYoutubeVideoUrl(e.target.value)}
            />
            <ErrorText error={error.url} className="" />
          </>
        )}
        {videoStatus.loading && <ProgressBar uploadProgress={videoUploadedProgress} cancelUpload={cancelUpload} />}
        <ModalFooter>
          <PrimaryButton
            text="Submit"
            type="button"
            loading={lessonState.is_loading.addLesson}
            onClick={videoFileInfo.file ? addLessonWithVideo : addLessonWithoutVideo}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddLessonFrom;
