import { combineReducers } from "redux";
import { pokemonApi } from "../services/sample";
import authSlice from "./features/Auth/auth.slice";
import lessonSlice from "./features/admin/lesson/lesson.slice";
import studentSlice from "./features/Student/student.slice";
import { instructorApi } from "../services/admin/instructor/instructor";
import { enrolledStudentApi } from "../services/admin/student/student";
import { addCourseBasicInfoApi } from "../services/admin/addCourse/basicInfo/course";
import { chapterApi } from "../services/admin/addCourse/chapter/chapter";
import { coursesApi } from "../services/admin/course/courses";

const RootReducer = combineReducers({
  authSlice,
  lessonSlice,
  [pokemonApi.reducerPath]: pokemonApi.reducer,
  [instructorApi.reducerPath]: instructorApi.reducer,
  [enrolledStudentApi.reducerPath]: enrolledStudentApi.reducer,
  [addCourseBasicInfoApi.reducerPath]: addCourseBasicInfoApi.reducer,
  [chapterApi.reducerPath]: chapterApi.reducer,
  [coursesApi.reducerPath]: coursesApi.reducer,
  studentSlice,
});

export default RootReducer;
