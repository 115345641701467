import React from 'react'
import { AdminLayout } from '../../../layout/admin/AdminLayout'
import CoursesTypography from '../../../shared/Typography/CoursesTypography'
import SudentCoursesMainWrapper from '../../../components/students/wrapper/StudentCoursesMainWrapper'
import LearningPathsComp from '../../../components/students/LearningPaths'

const LearningPaths = () => {
  return (
    <AdminLayout>
      <SudentCoursesMainWrapper>
        <CoursesTypography text="Learning Path" subtext="Achieve your learning goals with our step-by-step guided learning paths"/>
        <LearningPathsComp/>
      </SudentCoursesMainWrapper>
    </AdminLayout>
  )
}

export default LearningPaths
