/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Lesson from "./Lesson";
import { PrimaryButton } from "../../../../shared/button/Button";
import { addLesson } from "../../../../helpers/utils/image/imagePath";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { useAppSelector } from "../../../../store/store";

const Chapter = ({
  data,
  setOpenLessonModal,
  setActiveChapter,
  setOpenChapternModal,
  openDeleteModal,
  setActiveLesson,
  activeLesson,
}) => {
  const lessonState = useAppSelector((state) => state.lessonSlice);

  console.log("data--", data);

  const [allLessons, setAllLessons] = useState(data.lessons);
  const handleEdit = () => {
    setActiveChapter(data);
    setOpenChapternModal(true);
  };
  const openModalForAdd = () => {
    setActiveChapter(data);
    setOpenLessonModal(true);
  };
  useEffect(() => {
    if (lessonState.lesson_data?.chapter_id == data.id) {
      const arr = [...allLessons, lessonState.lesson_data];
      setAllLessons(arr);
    }
  }, [lessonState.lesson_data]);

  useEffect(() => {
    if (lessonState.is_success.deleteLesson && activeLesson.chapter_id == data.id) {
      const arr = allLessons.filter((e) => e.id != activeLesson.id);
      setAllLessons(arr);
    }
  }, [lessonState.is_success.deleteLesson]);

  return (
    <div className="curriculum-grid">
      <div className="curriculum-head">
        <p className="sc_lesson_title">{data.name}</p>
        <button to="#" className="btn" onClick={handleEdit}>
          Edit Chapter
        </button>
      </div>
      <div className="curriculum-info">
        {allLessons.map((lesson) => {
          return (
            <Lesson
              chapterId={data.id}
              key={lesson.id}
              openDeleteModal={openDeleteModal}
              lesson={lesson}
              setActiveLesson={setActiveLesson}
            />
          );
        })}
      </div>
      {/* empty section */}
      {/* <div className="sc_add_lesson_image_section">
        <img src={addLesson} />
        <span> Add lessons to your course.</span>
      </div> */}
      <div className="sc_add_lesson_button_row">
        <PrimaryButton text="Add Lesson" onClick={openModalForAdd} />
      </div>
    </div>
  );
};

export default Chapter;
