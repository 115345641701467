import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../settings/urlConfig";
import { TosatMessage } from "../../../helpers/utils/toaster";
import validateParams from "../../../helpers/utils/validateParams";
import { handleApiError } from "../../../helpers/utils/apiError/apiError";
import axiosInstance from "../../../settings/axiosInstance";

export const getStudentCourses = createAsyncThunk("getStudentCourses", async (data, rejectWithValue) => {
  const query = validateParams(data);

  try {
    const res = await axiosInstance.get(`${BASE_URL}/student/courses?${query}`);
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});

export const getCourseDetail = createAsyncThunk("getCourseDetail", async (id, rejectWithValue) => {
  try {
    const res = await axiosInstance.get(`${BASE_URL}/student/courses/${id}`);
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});
