import { ChevronDown, ChevronUp } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react'
import { Play,Lock} from "../../../../helpers/utils/image/imagePath";

const CourseContent = () => {
    const [active, setActive] = useState(false);
    const [progress, setProgress] = useState(50);
    const [currentIndex,setCurrentIndex] = useState(0);
    const handleCapterTitileClick = (index)=>{
      setCurrentIndex(index);

    }
    return (
      <>
        <div
          className={`card content-sec mb-3 ${active && "card__title__box__style"}`}
          onClick={() => setActive(!active)}
        >
          <div className="card-body py-3 course__body__style">
            <div className="course-card ">
              <h6 className="cou-title d-flex align-items-center mb-0">
                <div className="pe-3 chapter__name">Chapter 1</div>
                <div className=" d-flex ms-auto">
                  <p className="lessons__count m-0 me-4">8 Lessons</p>
                  {active ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </h6>
            </div>
          </div>
        </div>
  
        {active && (
          <div className={`card content-sec ${active && "card__margin"}`}>
            <div className="card-body pb-3 pt-2 ">
              <div className="course-card ">
                <div className="progress-stip">
                  <div className="progress-bar bg-success progress-bar-striped " style={{ width: `${progress}%` }}></div>
                </div>
                <div className="student-percent d-flex border-bottom ">
                  <p className="">10hrs</p>
                  <p className="ms-auto lessons__count ">50%</p>
                </div>
                <ul>
                  {[1, 2, 3, 4, 5].map((item,index) => (
                    <li className={`border-0 pb-0 course__content__title ${currentIndex === index && "active__title"}`} key={item} role='button' onClick={()=>handleCapterTitileClick(index)}>
                      <p style={{paddingRight:"10px"}}>Introduction</p>
                      <div>
                        <img src={ currentIndex === index ? Play : Lock} alt="" className="me-2" />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

export default CourseContent
