import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";

import { uploadFile } from "../../../../helpers/utils/image/imagePath";
import { getBase64 } from "../../../../helpers/utils/File/fileHelper";
import { DeleteTextButton } from "../../../../shared/button/Button";

const FileUploader = ({ file, setFileInfo }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const imageUrl = URL.createObjectURL(acceptedFiles[0]);
    const base64Image = await getBase64(acceptedFiles[0]);
    setFileInfo({ imageUrl, base64Image });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/png": [".png", ".PNG", ".jpg", ".JPG", ".JPEG", ".jpeg", ".svg", ".bmp"],
    },
  });
  return (
    <div className="form-control sc_basic_course_file_upload_section" {...getRootProps()}>
      {!file.imageUrl && (
        <div className="sc_file_upload_section">
          <img src={uploadFile} />
          <span className="sc_upload_file_description">
            Drop your images here, or <span className="sc_upload_file_colorful_text">browse</span>
          </span>
        </div>
      )}
      {file.imageUrl && (
        <div className="sc_show_selected_file_section">
          <img src={file.imageUrl} className="sc__selcted__file" />
          <div className="sc_delete_button_wrapper">
            <DeleteTextButton text="Remove Image" />
          </div>
        </div>
      )}
      <input {...getInputProps()} />
    </div>
  );
};

export default FileUploader;

FileUploader.propTypes = {
  file: PropTypes.object.isRequired,
  setFileInfo: PropTypes.func.isRequired,
};
