import React, { useEffect, useRef, useState } from "react";
import { Home, LogOut, Star } from "react-feather";
import { Link } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { fullLogo, userAvatar } from "../../helpers/utils/image/imagePath";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../store/features/Auth/auth.api";
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";

// eslint-disable-next-line react/prop-types
export function Header() {
  const [navbar, setNavbar] = useState(false);

  const [showProfile, setShowProfile] = useState(false);

  // Mobile Menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);

  const { user } = useSelector((state) => state.authSlice);

  const dispatch = useDispatch();

  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = () => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  return (
    <header className="header header-page">
      <div className="header-fixed">
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
        >
          <div className="container sc_nav_container">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#;" onClick={openMobileMenu}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Link>
              <Link to="/" className="navbar-brand logo">
                <img src={fullLogo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="sc_right_nav_section">
              <div className="main-menu-wrapper sc_nav_list_right_margin">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={fullLogo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link id="menu_close" className="menu-close" to="#;" onClick={hideMobileMenu}>
                    <i className="fas fa-times"></i>
                  </Link>
                </div>
                <ul className="main-nav">
                  <li className="has-submenu active">
                    <Link to="/">My Courses</Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/">Instructors</Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/">Students</Link>
                  </li>
                </ul>
              </div>
              <ul className="nav header-navbar-rht">
                <li className="nav-item user-nav">
                  <Link
                    to="#"
                    className={showProfile ? "dropdown-toggle show" : "dropdown-toggle"}
                    data-bs-toggle="dropdown"
                    onClick={profileClick}
                  >
                    <span className="user-img">
                      <img src={user?.avatar_url || userAvatar} alt="" />
                      <span className="status online"></span>
                    </span>
                  </Link>
                  <div
                    ref={profile}
                    className={
                      showProfile
                        ? "users dropdown-menu dropdown-menu-right show modalPosition"
                        : "users dropdown-menu dropdown-menu-right"
                    }
                    data-popper-placement="bottom-end"
                  >
                    <div className="user-header">
                      <div className="avatar avatar-sm">
                        <img
                          src={user?.avatar_url || userAvatar}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="user-text">
                        <h6>{`${user?.first_name} ${user?.last_name}`}</h6>
                        <p className="text-muted text mb-0">{capitalizeFirstLetter(user?.role)}</p>
                      </div>
                    </div>
                    <Link className="dropdown-item text" to="/instructor-dashboard">
                      <Home size={14} color={"#FF875A"} className="headerIcon" /> Dashboard
                    </Link>
                    <Link className="dropdown-item text" to="/instructor-edit-profile">
                      <Star size={14} color={"#FF875A"} className="headerIcon" /> Edit Profile
                    </Link>

                    <Link className="dropdown-item text" to="/">
                      <LogOut size={14} color={"#FF875A"} className="headerIcon" /> Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className={mobileMenu ? "sidebar-overlay opened" : "sidebar-overlay"}></div>
      </div>
    </header>
  );
}
