import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import CKTextEditor from "../../../../shared/CKTextEditor/CKTextEditor";
import RadioButton, { Radio } from "../../../../shared/radioButton/RadioButton";
import Footer from "./Footer";
import { DangerButton } from "../../../../shared/button/Button";
import ErrorText from "../../../../shared/inputs/errorText/ErrorText";
import { useUpdateCourseRequirementsMutation } from "../../../../services/admin/addCourse/basicInfo/course";

// eslint-disable-next-line react/prop-types
const AddSettings = ({ nextTab3, prevTab2 }) => {
  const [requirements, setRequirements] = useState("");
  const [publish, setPublish] = useState(false);
  const [draft, setDraft] = useState(true);
  const [requirementsError, setRequirementsError] = useState("");
  const { id } = useParams();

  console.log("requirements--", requirements);
  const [updateCourseRequirements, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdateCourseRequirementsMutation();

  const enablePublish = () => {
    setDraft(false);
    setPublish(true);
  };

  const enableDraft = () => {
    setPublish(false);
    setDraft(true);
  };

  const handleError = () => {
    if (!requirements) {
      setRequirementsError("Requirement is Required");
    } else {
      setRequirementsError("");
    }
  };

  const clearFrom = () => {
    setRequirements("");
    setPublish(false);
    setDraft(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleError();
    if (requirementsError) {
      return;
    }
    const data = { id: id, requirements: requirements };
    if (publish) {
      data.status = "published";
    }
    if (draft) {
      data.status = "published";
    }
    updateCourseRequirements(data);
  };

  useEffect(() => {
    if (isUpdated) {
      clearFrom();
      nextTab3();
    }
  }, [isUpdated]);

  return (
    <>
      <fieldset className="field-card" style={{ display: "block" }}>
        <form onSubmit={handleSubmit}>
          <div className="add-course-info">
            <div className="add-course-inner-header">
              <div className="d-flex flex-row justify-content-between">
                <h4>Requirements</h4> <DangerButton text="Delete Course" onClick={undefined} />
              </div>{" "}
            </div>

            <div className="add-course-form">
              <div className="form-group mb-0">
                <label className="add-course-label">Requirements</label>
                <div id="editor">
                  <CKTextEditor data={requirements} setData={setRequirements} />
                </div>
                <ErrorText error={requirementsError} />
              </div>
              <div className="mt-3" />
              <RadioButton className="d-flex ">
                <Radio
                  className="mr-10"
                  label="Draft"
                  value={draft}
                  setValue={enableDraft}
                  description="Your course will be saved as draft."
                />
                <Radio
                  className="mx-5"
                  label="Publish"
                  value={publish}
                  setValue={enablePublish}
                  description="Your course will be published right now."
                />
              </RadioButton>
              <div className="mb-2" />
            </div>

            <Footer prevTab2={prevTab2} loading={isUpdating} />
          </div>
        </form>
      </fieldset>
    </>
  );
};

export default AddSettings;
