import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CourseDetailsContent from "./CourseDetailsContent";
import CourseInstBannerSection from "./CourseInstBannerSection";
import CourseBreadCrumbBar from "./Contents/CourseBreadCrumbBar";
import Empty from "../../../shared/Empty/Empty";
import { getCourseDetail } from "../../../store/features/Student/student.api";

const CourseDetailsComp = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourseDetail(params.courseId));
  }, []);

  const { course } = useSelector((state) => state.studentSlice);

  return course ? (
    <div>
      <CourseBreadCrumbBar courseTitle={course?.name} />
      <CourseInstBannerSection />
      <CourseDetailsContent />
    </div>
  ) : (
    <Empty emptyText="No Course details added." />
  );
};

export default CourseDetailsComp;
