/* eslint-disable react/prop-types */
import React from "react";
import { X } from "react-feather";

const UploadProgress = ({ progress, clearFile }) => {
  return (
    <div className="mb-3">
      <label className="add-course-label">Upload File</label>
      <div className="sc_divider  mb-3" />
      <div className="d-flex justify-content-between mb-2">
        <div className="sc_uploaded_file_name">Laws of UI/UX.pdf </div>
        <X size={15} className="sc_cancel_upload__icon" onClick={clearFile} />
      </div>

      <div className="course-stip progress-stip file-upload">
        <div className="progress-bar active-stip sc_active__bg" style={{ width: progress + "%" }} />
      </div>
      <div className="sc_show_uploading_precentage mt-1"> (Uploading {progress}%)</div>
    </div>
  );
};

export default UploadProgress;
