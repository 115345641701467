import React from "react";
import { useSelector } from "react-redux";

import VideoCard from "./Contents/VideoCard";
import CourseOverviewSection from "./Contents/CourseOverviewSection";
import IncludesSection from "./Contents/IncludesSection";
import PdfViewer from "./Contents/PdfViewer";
import CourseContent from "./Contents/CourseContent";

const CourseDetailsContent = () => {
  const { course } = useSelector((state) => state.studentSlice);

  return (
    <section className="page-content course-sec">
      <div className="container">
        <div className="row" id="1">
          <div className="col-lg-8">
            {/* Overview */}
            <CourseOverviewSection />

            {/* /Overview */}
          </div>
          <div className="col-lg-4">
            <IncludesSection />
          </div>
        </div>
        <div className="row" id="2">
          <div className="col-lg-4">
            <div className="row ">
              <div className="">
                <h5 className="subs-title mb-0  p-4">Course Content</h5>
              </div>
            </div>

            {[1, 2, 3, 4, 5].map((item) => (
              <>
                <CourseContent key={item} />
              </>
            ))}
          </div>
          <div className="col-lg-8">
            <>
              <VideoCard videoUrl="https://joy1.videvo.net/videvo_files/video/free/2012-09/large_watermarked/hd0456_preview.mp4" />

              {/* <PdfViewer  pdfUrl={"https://theagilecoach-uploads.s3.us-east-2.amazonaws.com/0gW1Wzw4CB6ptdYGWhbL.pdf"}/> */}
            </>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseDetailsContent;
