import React from 'react'
import { AdminLayout } from '../../../layout/admin/AdminLayout'
import SudentCoursesMainWrapper from '../../../components/students/wrapper/StudentCoursesMainWrapper'
import CoursesTypography from '../../../shared/Typography/CoursesTypography'
import LearningPathCoursesComp from '../../../components/students/LearningPathCourses'


const LerningPathCourses = () => {
  return (
    <AdminLayout>
    <SudentCoursesMainWrapper>
      <CoursesTypography text="Learning Path" subtext="Achieve your learning goals with our step-by-step guided learning paths"/>
      <LearningPathCoursesComp/>
    </SudentCoursesMainWrapper>
  </AdminLayout>
  )
}

export default LerningPathCourses
