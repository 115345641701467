import React from "react";
import AdminLayoutWithoutSidebar from "../../../layout/admin/AdminLayoutWithoutSidebar";
import AddNewCourseMainWrapper from "./Wrapper";
import AddNewCourseTopography from "../../../components/admin/addNewCourse/AddNewCourseTopography";
import AddNewCourseTab from "../../../components/admin/addNewCourse";

const AddNewCourse = () => {
  return (
    <AdminLayoutWithoutSidebar>
      <AddNewCourseMainWrapper>
        <AddNewCourseTopography />
        <AddNewCourseTab />
      </AddNewCourseMainWrapper>
    </AdminLayoutWithoutSidebar>
  );
};

export default AddNewCourse;
