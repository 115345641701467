import React from "react";
import LearningPathCourseCard from "../../../shared/Card/LearningPathCourseCard";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { learningPathCoursesData } from "../LearningPathCourses/helper";
import SearchBar from "../../../shared/searchBar/SearchBar";
import Empty from "../../../shared/Empty/Empty";

const LearningPathsComp = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const handleClick=(id)=>{
    console.log(id);
    navigate(`/learning-path-courses/${id}`)

  }
  return (
    <div className="container">
      <div className="student-widget-group p-0 mt-4">
        <div className="row">
          <div className="col-lg-12 ">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />

            <div className="row">
              {learningPathCoursesData.map((item) => (
                <LearningPathCourseCard
                  onClick={(e)=>handleClick(item.id)}
                  key={item.id}
                  title={item.course_name}
                  // imageUrl={item.image_url}
                  courseCount={item.course_count}
                  courseDuration={item.course_duration}
                  courseDescription={item.course_description}
                />
              ))}


              {/* Empty Screen*/}
              {/* <Empty emptyText="No Learning paths added."/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningPathsComp;
