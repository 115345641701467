import { isRejectedWithValue } from "@reduxjs/toolkit";
import { TosatServiMessage, TosatServiceError } from "../helpers/utils/toaster";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // if data added and updated successfully
  if (action?.payload?.success && action?.payload?.message) {
    TosatServiMessage(action.payload.message);
  }
  if (isRejectedWithValue(action)) {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    // toast.warn({ title: "Async error!", message: action.error.data.message });
    TosatServiceError(action.payload);
  }

  return next(action);
};
