import React, { useEffect, useState } from "react";
import AvatarSection from "../../../shared/AvatarSection/AvatarSection";
import ProfileInfoTypography from "./ProfileInfoTypography";
import ProfileForm from "./ProfileForm";
import ProfilePictureForm from "./ProfilePictureForm";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../store/features/Auth/auth.api";

const StudentProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authSlice);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  return (
    <div className="main-wrapper">
      <div className="page-content p-0 ">
        <div className="row">
          {/* Profile Details */}
          <div className="col-xl-12">
            <div className="settings-widget profile-details border-top-0 ">
              <div className="settings-menu p-0 ronded-0">
                <ProfilePictureForm />

                <div className="checkout-form personal-address add-course-info">
                  <ProfileInfoTypography />
                  <ProfileForm />
                </div>
              </div>
            </div>
          </div>
          {/* Profile Details */}
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
