import React from "react";
import PropTypes from "prop-types";

import { Header } from "./Header";
import Sidebar from "./Sidebar";

const AdminLayoutWithoutSidebar = ({ children }) => {
  return (
    <div className="main-wrapper">
      <Header activeMenu={"Dashboard"} />

      {children}
    </div>
  );
};

export default AdminLayoutWithoutSidebar;

AdminLayoutWithoutSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};
